import React, { useEffect } from "react";
// import { useLocation } from "react-router-dom";

const NotEligible = () => {
  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      window.history.pushState(null, null, "/");
      window.location.replace("/select-type"); 
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const message = searchParams.get("message");

  return (
    <div className="alredy">
      <h1>You are not eligible for this game</h1>
    </div>
  );
};

export default NotEligible;
