import React from "react";
import { Link } from "react-router-dom";

const TermsCondition = () => {
  return (
    <>
      <div className="how-to-play">
        <div className="how-to-play-wrapper">
          <button className="btn-start cr">Terms and Conditions</button>
          <div className="how-to-play-wrapper-content">
            <div className="how-to-play-wrapper-content-scroll">
              <p>
                <span>1. Hyundai Motor India Limited </span> (HMIL) organizes
                the <span> “Hyundai Game Show” (“Contest”) </span> only for the
                <span> Employees of HMIL (“Participants”) </span>
                between September 01, 2024 and November 30, 2024 (“
                <span>Term</span>”).
              </p>
              <p>
                <span>2.</span> This Contest is open only to Indian Nationals
                residing in India. No purchase is necessary to enter any
                Contest.
              </p>
              <p>
                <span>3.</span> During the Term, the interested Participants
                shall visit the microsite{" "}
                <a href="https://www.hyundaikbcgameshow.com/" target="_blank" rel="noopener noreferrer">
                  www.hyundaikbcgameshow.com{" "}
                </a>
                (hereinafter called as the “Site”), fill the information (Name,
                Employee ID, Email ID, etc.) and answer the one master question
                to register for the Contest.
              </p>
              <p>
                <span>4.</span> The Participants who correctly answer the master
                question, shall be qualify for the main round.
              </p>
              <p>
                <span>5.</span>In the main rounds,the shortlisted Participants shall answer a
                series of 15 questions about HMIL & its parent company
                worldwide, within the stipulated timeframe of 45 seconds.
              </p>
              <p>
                <span>6.</span> At the conclusion of the main round, HMIL shall select a
                total of 10 winners from the participating HMIL employees, based
                on the maximum number of correct answers made within shortest
                time. In the event of tie, the Participant who has given the
                maximum number of correct answers first, based on the date and
                time of receipt of answers, will be declared as winner.
              </p>
            
              <p>
                <span>7.</span> The 10 selected winners will get the opportunity to
                be a part of audience of {" "}
                <span> Kaun Banega Crorepati-Season 16</span> episode at
                <span> Filmcity Studio, Mumbai </span>with a maximum stay for 2
                days and one night in Mumbai (<span>Program</span>), subject to
                Force Majeure events. The above stay is only for winners not
                residing in Mumbai.
              </p>
              <p>
                <span>8.</span> The gratification is non-transferable and
                non-refundable and cannot be exchanged/ redeemed for cash. No
                cash claim will be made in lieu of the same. HMIL may at its
                sole discretion substitute or replace the gratification with any
                other equal or similar value (in kind) selected by it without
                prior notice.
              </p>
              <p>
                <span>9.</span> The costs of Travel, Boarding and Lodging of
                the winners will be borne by HMIL for its employees.
              </p>
              <p>
                <span>10.</span> HMIL employee should take prior approval from
                their reporting manager for the travel.
              </p>
              <p>
                <span>11.</span> In case of any symptoms of cough / cold /
                fever, etc. the winner will not be allowed to travel.
              </p>
              <p>
                <span>12.</span> All the terms & conditions of the third party
                (Tour Operator, Hotels, Services, Service provider etc.) are
                applicable and to be abided by the winners. HMIL is not
                responsible for any mishap or unforeseen circumstances that may
                occur from the commencement of trip and till return to the home.
              </p>

              <p>
                <span>13.</span> The decision of HMIL in selecting the winners
                will be final and binding on all the Participants and no
                correspondence challenging such eligibility and criteria, shall be entertained under any
                circumstances of whatsoever nature.
              </p>
              <p>
                <span>14.</span> The winners will be intimated by way of
                email/Site. In the event of no response/acknowledgement/claim is
                made by the concerned winner to the email within 2 days, his/her
                claim to the gratification will be forfeited and HMIL may select
                another winner in his/her place.
              </p>
              <p>
                <span>15.</span> The winners should submit to HMIL the necessary
                documentation/proof of identity and other relevant
                documentation, within 5 days of receiving email intimation of
                being selected as winner of the Contest.
              </p>
              <p>
                <span>16.</span> HMIL reserves the right at its sole discretion
                to disqualify any Participant that it determines to be tampering
                with the process or the operation of the Event, to be acting in
                breach or potential breach of these Terms and Conditions.
              </p>
              <p>
                <span>17.</span> HMIL is not liable or responsible in the event
                the channel Sony India refuses to provide or cancels the
                opportunity to physically visit the Program live due to any
                reasons.
              </p>
              <p>
                <span>18.</span> HMIL reserves the right to withdraw/terminate
                the Contest at any given point of time without any prior notice
                and/or alter/change any of the terms & conditions of the
                Contest, process or means of gratification without prior notice.
              </p>
              <p>
                <span>19.</span> All Participants and winners waive any and all
                rights of claim with regards to the Contest against HMIL.
              </p>
              <p>
                <span>20.</span> The Contest is subject to force majeure
                conditions and HMIL shall not be held liable or responsible for
                damages or deficiency in service or loss, injury, expense, etc.,
                in the event of the Program being delayed or cancelled or
                aborted or prohibited by Sony Television to be part of audience
                during the shoot due to any force majeure events including but
                not limited to Acts of God, natural calamities, prohibition
                orders, Government restrictions, lockdown, epidemic, pandemic,
                local unrest, traffic jams, medical emergency, accidents, etc.
                <p></p>
                HMIL shall not be responsible and/or liable for any damages
                caused to the 10 shortlisted participants due to Force Majeure Events or for the
                temporary or permanent loss of or damage to baggage or personal
                effects or for any death, personal injury, sickness, accident,
                loss, delay, discomfort, increased expenses, consequential loss
                and/or damage or any kind of theft howsoever caused.
              </p>
              <p>
                <span>21.</span> Participants unconditionally and irrevocably
                agree to hold HMIL, its officers, directors, representatives and
                the Service Providers harmless and indemnified against any loss,
                damage, claim, suit, compensation, attorney expenses, criminal
                prosecution, Order, etc. arising out of any injury, damage, loss
                of life of Participants or due to direct or indirect acts of
                negligence, default, omission and commission by the
                Participants.
              </p>
              <p>
                <span>22.</span> The Participants would be solely held liable
                for any details including but not restricted to age, name,
                profession, investment declaration entered by them.
              </p>
              <p>
                <span>23.</span> Entry to the Contest is void where prohibited.
              </p>
              <p>
                <span>24.</span> Any dispute arising out of this Contest or
                otherwise shall be subject to exclusive jurisdiction of courts
                of New Delhi.
              </p>
              <p>
                <span>25.</span> By participating in this Contest, the entrants
                accept all the terms and conditions as mentioned hereinabove.
              </p>
            </div>
          </div>
          <Link to="/hmil">
            <button className="btn-start">Accept </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default TermsCondition;
