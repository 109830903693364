import React from "react";
import Getstart from "./component/Getstart";
import { Route, Routes } from "react-router-dom";
import HowToPlay from "./component/HowToPlay";
import TermsCondition from "./component/TermsCondition";
import Hmil from "./component/Hmil";
import StoreHmil from "./component/StoreHmil";
import ThankYou from "./component/ThankYou";
import Already from "./component/Already";
import cars from "./component/assets/cars.png";
import NotEligible from "./component/NotEligible";

const App = () => {
  return (
    <>
      <div className="main"></div>
      <div className="main-mobile"></div>
      <div className="page">
        <Routes>
          <Route path="/" element={<Getstart />} />
          <Route path="/how-to-play" element={<HowToPlay />} />
          <Route path="/terms-and-condition" element={<TermsCondition />} />
          <Route path="/hmil" element={<Hmil />} />
          <Route path="/store-hmil" element={<StoreHmil />} />
          <Route path="/Thank-you" element={<ThankYou />} />
          <Route path="/already-registered" element={<Already />} />
          <Route path="/not-eligible" element={<NotEligible />} />

        </Routes>
        <div className="cars">
          <img src={cars} alt="" />
        </div>
      </div>
    </>
  );
};

export default App;
