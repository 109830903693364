import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Hmil = () => {
  const [name, setName] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    employeeId: "",
    email: "",
  });
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const allowedDomains = ["hmil.net", "hyundai.com", "hmie.co.in"];
    const [user, domain] = email.split("@");
    if (!allowedDomains.includes(domain)) {
      return false;
    }
    return true;
  };

  const handlePopupSubmit = () => {
    const newErrors = {
      name: name ? "" : "Name is required",
      employeeId: employeeId ? "" : "Employee ID is required",
      email: email ? "" : "Email is required",
    };
    if (!newErrors.email && !validateEmail(email)) {
      newErrors.email = "Invalid email address";
    }

    if (newErrors.name || newErrors.employeeId || newErrors.email) {
      setErrors(newErrors);
      return;
    }
    
    const data = {
      name: name,
      employee_id: employeeId,
      email: email,
    };

    fetch("https://hyundai.warburttons.com/api/hmil", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.status === "success") {
          console.log("Data sent successfully:", responseData);
          navigate("/store-hmil");
          setShowPopup(true);
          localStorage.setItem(
            "responseData",
            JSON.stringify(responseData.data)
          );
          localStorage.setItem("user_id", responseData.user_id);
          // console.log("alredy:", responseData.message);

          // console.log(localStorage);
        } else if (responseData.status === 201) {
          // console.log("alredy:", responseData.message);
          navigate("/already-registered");
          
        } 
        else if (responseData.status === 204) {
          // console.log("alredy:", responseData.message);
          navigate("/not-eligible");
        } 
        else {
          console.error("Error sending data:", responseData.error);
        }
      })
      .catch((error) => {
        console.error("Error sending data:", error);
      });
  };

  return (
    <>
      <div className="hmil-form">
        <div className="form-header">
          <h6>HMIL</h6>
        </div>
        <div className="form-header-form">
          <input
            type="text"
            placeholder={errors.employeeId ? "Name is required" : "Name"}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setErrors({ ...errors, name: "" });
            }}
            style={{
              borderColor: errors.name ? "red" : "",
              outline: errors.name ? "1.4px solid red" : "",
              placeholderTextColor: errors.name ? "red" : "",
            }}
          />
          <input
            type="number"
            placeholder={
              errors.employeeId ? "Employee ID is required" : "Employee ID"
            }
            value={employeeId}
            onChange={(e) => {
              const inputValue = e.target.value;
              // Check if the input is empty or a positive integer with a maximum of 6 digits
              if (inputValue === "") {
                setEmployeeId(inputValue);
                setErrors({ ...errors, employeeId: "" }); // Clear the error message when input is empty
              } else if (/^\d+$/.test(inputValue) && inputValue.length <= 6) {
                setEmployeeId(inputValue);
                setErrors({ ...errors, employeeId: "" });
              } else {
                // Display an error message for invalid input
                setErrors({
                  ...errors,
                  employeeId:
                    "Employee ID must be a numeric value with a maximum of 6 digits",
                });
              }
            }}
            style={{
              borderColor: errors.employeeId ? "red" : "",
              outline: errors.employeeId ? "1.4px solid red" : "",
              placeholderTextColor: errors.employeeId ? "red" : "",
            }}
            maxLength={6} // Restrict input to a maximum of 6 digits
          />
          <input
            type="email"
            placeholder={errors.email ? "Email is required" : "Email"}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrors({ ...errors, email: "" });
              const lowercaseEmail = e.target.value.toLowerCase();
              setEmail(lowercaseEmail);
            }}
            onFocus={() => setIsEmailFocused(true)}
            onBlur={() => setIsEmailFocused(false)}
            style={{
              borderColor: validateEmail(email) ? "" : "red",
              outline: errors.email ? "1.4px solid red" : "",
              placeholderTextColor: errors.email ? "red" : "",
            }}
          />

          {isEmailFocused && !validateEmail(email) && (
            <p className="error-messages">
              Only hmil.net, hyundai.com and hmie.co.in emails are allowed.
            </p>
          )}

          <button
            className="btn-start"
            onClick={() => {
              const newErrors = {
                name: name ? "" : "Name is required",
                employeeId: employeeId ? "" : "Employee ID is required",
                email: email ? "" : "Email is required",
              };
              if (!newErrors.email && !validateEmail(email)) {
                newErrors.email = "Invalid email address";
              }

              if (newErrors.name || newErrors.employeeId || newErrors.email) {
                setErrors(newErrors);
              } else {
                setShowPopup(true);
              }
            }}
          >
            Submit
          </button>
        </div>
      </div>
      {showPopup && (
        <>
          <div className="overlay">
            <div className="popup">
              <p className="close" onClick={() => setShowPopup(false)}>
                &times;
              </p>

              <div className="popup-head">
                <h6>Confirmation</h6>
              </div>
              <div className="popup-head-wrapper">
                <div className="popup-head-wrapper-details">
                  <div>
                    <h3>Employee Name</h3>
                    <h3>{name}</h3>
                  </div>
                  <div>
                    <h3>Employee ID</h3>
                    <h3>{employeeId}</h3>
                  </div>
                  <div>
                    <h3>Email</h3>
                    <h3>{email}</h3>
                  </div>
                </div>

                <div className="popup-submit">
                  <Link to="/store-hmil">
                    <button className="submit-btn" onClick={handlePopupSubmit}>
                      Submit
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Hmil;
