import React from "react";
import { Link } from "react-router-dom";

const HowToPlay = () => {
  return (
    <>
      <div className="how-to-play">
        <div className="how-to-play-wrapper">
          <button className="btn-start cr">How to Play</button>
          <div className="how-to-play-wrapper-content">
            <div className="how-to-play-wrapper-content-scroll">
              <h2>Main Game Show</h2>
              <p>
                Step 1: Please read the Terms and Conditions carefully before accepting.
              </p>
              <p>Step 2: Enter your details. All information is mandatory.</p>
              <p>Step 3: Preview your details for final check and submit.</p>
              <p>Step 4: A total of 15 questions have to be answered.</p>
              <p>
                Step 5: First question will pop up as soon as you confirm your details. Every question will have 4 answer choices.
              </p>
              <p>
                Step 6: The timer will immediately start as soon as the question is served.
              </p>
              <p>Step 7: Contestants will get maximum 45 seconds to answer.</p>
            </div>
          </div>
          <Link to="/terms-and-condition">
            <button className="btn-start">Next</button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default HowToPlay;
