import React from "react";
import { Link } from "react-router-dom";
import arrowright from "./assets/arrowright.svg"
const Getstart = () => {
  return (
    <>
      <div className="get-start">
        <h1>
          Get ready to play and get a chance to be <br /> a part of the audience at the
          KBC shoot in Mumbai.
        </h1>
      <Link to='/how-to-play'>  <button className="btn-start"> Let's Play <img src={arrowright} alt="" /></button></Link>
      </div>
    </>
  );
};

export default Getstart;
