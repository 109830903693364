import React, { useEffect } from "react";

const ThankYou = () => {
  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      window.history.pushState(null, null, "/");
      window.location.replace("/");
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  return (
    <>
      <div className="thankyou">
        <h1>Thank You</h1>
        <h5>
          for your participation. <br />
          Winners will be announced soon.
        </h5>
      </div>
    </>
  );
};

export default ThankYou;
