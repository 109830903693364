import React, { useState, useEffect } from "react";
import ThankYou from "./ThankYou";
import time from "./assets/timer.svg";

const StoreHmil = () => {
  const storedQuestions = localStorage.getItem("responseData");
  const storedUserId = localStorage.getItem("user_id");

  // Parse stored data if it exists, or provide default values
  const questions = storedQuestions ? JSON.parse(storedQuestions) : [];
  const user_id = storedUserId ? JSON.parse(storedUserId) : null;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [timer, setTimer] = useState(46);
  const [isLoading, setIsLoading] = useState(true);
  const [showQuestion, setShowQuestion] = useState(false); // New state

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      window.history.pushState(null, null, "/");
      window.location.replace("/terms-and-condition");
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  useEffect(() => {
    if (quizCompleted) {
      console.log("Quiz completed!");
      console.log("Selected Answers:", selectedAnswers);
    }
  }, [quizCompleted, selectedAnswers, timer]);

  useEffect(() => {
    if (!quizCompleted && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    } else if (!quizCompleted && timer === 0) {
      handleOptionSelect(null); // Automatically submit the selected option
    }
  }, [quizCompleted, timer]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setShowQuestion(true);
    }, 1000); // 1 second delay

    return () => clearTimeout(timerId); // Cleanup the timer on component unmount
  }, []);

  const handleOptionSelect = (optionIndex) => {
    setSelectedOptionIndex(optionIndex);

    const selectedOption = questions[currentQuestionIndex].options[optionIndex];
    const selectedQuestion = questions[currentQuestionIndex].question;

    const answer = {
      question: selectedQuestion,
      selectedOption: selectedOption,
    };

    setSelectedAnswers((prevSelectedAnswers) => [
      ...prevSelectedAnswers,
      answer,
    ]);

    // Submit the answer data here
    submitAnswer(answer);

    setTimeout(() => {
      setSelectedOptionIndex(null);

      if (currentQuestionIndex + 1 < questions.length) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setTimer(46);
      } else {
        setQuizCompleted(true);
      }
    }, 0);
  };

  const MAX_QUESTIONS = 15;

  const currentQuestion = questions[currentQuestionIndex];
  let ques, opts;
  if (currentQuestion) {
    for (let i = 0; i < currentQuestion.options.length; i++) {
      ques = currentQuestion.options[i].question_id;
      opts = currentQuestion.options[i].correct;
    }
  }

  if (quizCompleted) {
    return (
      <div className="thankyou">
        <ThankYou />
      </div>
    );
  }

  const submitAnswer = (answer) => {
    console.log("Submitting answer:", answer);

    let requestData = {
      user_id: user_id,
      question_id: ques,
      option_id: opts,
      time_elapsed: String(46 - timer),
      answer: null,
    };

    if (answer.selectedOption) {
      requestData = {
        ...requestData,
        question_id: answer.selectedOption.question_id,
        option_id: answer.selectedOption.option,
        answer: answer.selectedOption.correct,
      };
    }
    const apiUrl = "https://hyundai.warburttons.com/api/result";

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div className="store">
        <div className="store-wrapper">
          {isLoading ? (
            <p>Loading questions...</p>
          ) : (
            <>
              {showQuestion && (
                <>
                  <h1 className="size-big">
                    Give the correct answer to the below question
                  </h1>
                  <p>
                    {" "}
                    <img src={time} alt="" /> {timer}{" "}
                  </p>
                  <div className="store-wrapper-option">
                    {currentQuestion && currentQuestionIndex < MAX_QUESTIONS && (
                      <div>
                        <div className="store-wrapper-option-question">
                          <h2>
                            Q {currentQuestionIndex + 1}. {currentQuestion.label}
                          </h2>
                        </div>
                        <div className="store-wrapper-option-answer">
                          {currentQuestion.options.map((option, index) => (
                            <div
                              key={option.id}
                              className={`store-wrapper-option-answer-field ${
                                selectedOptionIndex === index ? "selected" : ""
                              }`}
                              onClick={() => handleOptionSelect(index)}
                            >
                              <h5>{String.fromCharCode(65 + index)} :</h5>
                              <h5>{option.label}</h5>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default StoreHmil;
